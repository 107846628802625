import React from 'react';
import { Link } from 'gatsby';
import { useHeroContent } from '../../hooks/getHeroContent';

const Hero = () => {
    const data = useHeroContent();
    const heroData = data.wordpressPage.acf;
    return (
        <div>
            <div className='resp_image_bg'>
                <div className='overlay-hero-img'></div>
                <div className="videotxt">
                    <h3
                        className="yellowtxt"
                        dangerouslySetInnerHTML={{
                            __html: heroData.heroWelcomeTitle,
                        }}
                    />
                    <h1
                        dangerouslySetInnerHTML={{
                            __html: heroData.heroSubtitle,
                        }}
                    />
                    <Link
                        to={heroData.heroButtonLink}
                        className="wrtsbtn yellowbtn display_none"
                        dangerouslySetInnerHTML={{
                            __html: heroData.heroButtonText,
                        }}
                    />
                </div>

            </div>


{/* 
            <div className="resp-container">
                <div className="videoopacity"></div>
                <div className="videotxt">
                    <h3
                        className="yellowtxt"
                        dangerouslySetInnerHTML={{
                            __html: heroData.heroWelcomeTitle,
                        }}
                    />
                    <h1
                        dangerouslySetInnerHTML={{
                            __html: heroData.heroSubtitle,
                        }}
                    />
                    <Link
                        to={heroData.heroButtonLink}
                        className="wrtsbtn yellowbtn"
                        dangerouslySetInnerHTML={{
                            __html: heroData.heroButtonText,
                        }}
                    />
                </div>
                <video autoPlay muted loop playsInline className="hero_homepage_video">
                    <source
                        src={heroData.hero_video_file_mp4.source_url}
                        type="video/mp4"
                    />
                    <source
                        src={heroData.hero_video_file_webp.source_url}
                        type="video/webm"
                    />
                    Your browser does not support the video tag.
                </video>
            </div> */}

        </div>
    );
};

export default Hero;
